<template>
  <div id="admin-panel">
    <Header active_tab="mentorship"></Header>
    <div v-if="user.data.admin">
      <div class="row">
        <div class="col">
          <SmallCard
            :is-notif="true"
            :number-of-notifications="numberOfNotifications"
            address="/time-table-notifications/"
            link="ant-design:notification-outlined"
            title="Notifications"
          />
          <SmallCard
            address="/google-docs-upload"
            link="teenyicons:doc-outline"
            title="Google Docs"
          ></SmallCard>
          <SmallCard
            address="/essay-notification"
            link="teenyicons:doc-outline"
            title="Essay & Writing"
            :is-notif="true"
            :number-of-notifications="numberOfEssay"
          ></SmallCard>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col">
          <SmallCard
            title="TimeTable Reports"
            link="ic-outline-access-time"
            address="/reports"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import { defineAsyncComponent } from "vue";
import store from "@/store";
import db from "@/firebase";
const SmallCard = defineAsyncComponent(() =>
  import("@/components/SmallCard.vue")
);
const Header = defineAsyncComponent(() => import("@/components/Header"));

export default {
  name: "Schedule Making",
  mounted() {
    store.state.loading = false;

    db.collection("time_table_notifications")
      .get()
      .then(data => {
        data.docs.forEach(notif => {
          if ("message" in notif.data()) this.numberOfNotifications++;
        });
      });

    db.collection("essay_upload_notification")
      .get()
      .then(data => {
        this.numberOfEssay = data.docs.length;
      });
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  components: {
    SmallCard,
    Header
  },
  data() {
    return {
      numberOfNotifications: 0,
      numberOfEssay: 0
    };
  }
};
</script>
<style scoped>
#admin-panel {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  /* background: var(--light-gray); */
  background-image: linear-gradient(white 5%,red);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
</style>
